const CheckBoxes = ({
    name,
    handleCheckboxFilter,
    checked,
    isChecked,
    isDisabled,
    handleDepartmentType,
}) => (
    <div className='checkBoxes__item'>
        <div>{name.toUpperCase()} </div>
        <input
            key={name}
            id={name}
            name={name}
            type='checkbox'
            onChange={
                handleCheckboxFilter
                    ? handleCheckboxFilter
                    : handleDepartmentType
            }
            checked={isChecked || name === checked}
            disabled={isDisabled ? true : undefined}
        />
        <label htmlFor={name}> {name} </label>
    </div>
);
export default CheckBoxes;
