import React from "react";
import "../Home/style.css";
import renderCheckBoxes from "../Helpers/renderCheckBoxes";
import SearchBar from "../Common/SearchBar";

import GenerateTicketTable from "../utils/generateTicketTable";
import filterAll from "../utils/filterAll";
import { filterBy } from "../utils/filterBy";
import { fetchPendingTickets } from "../../services/api";
import TicketTemplate from "../TicketTemplate";
import "./style.css";
import { DEPARTMENT_CHECKBOXES } from "../../constants";

class Completed extends React.Component {
    constructor() {
        super();

        this.state = {
            ticket: [],
            filteredTicket: [],
            groupByTicket: [],
            itemData: {},
            search: "",
            filterAll: [],
            filterBy: "",
            department: DEPARTMENT_CHECKBOXES,
            showPendings: true,
            showTicketForm: false,
            hasSearched: false,
        };

        this.handleCheckboxFilter = this.handleCheckboxFilter.bind(this);
        this.handleDepartmentType = this.handleDepartmentType.bind(this);
    }

    handleCheckboxFilter(event) {
        const { name, checked } = event.target;

        document
            .getElementById("groupBy")
            .querySelectorAll("input[type=checkbox]")
            .forEach((el) => {
                el.checked = false;
                this.setState({
                    filterBy: "",
                });
            });

        document
            .getElementById("groupBy")
            .querySelectorAll("input[type=checkbox]")
            .forEach((el) => {
                if (el.name === name && el.checked !== checked) {
                    el.checked = true;
                    this.setState({
                        filterBy: name,
                    });
                }
            });
    }

    handleDepartmentType = (event) => {
        const { name } = event.target;
        const { tickets } = this.state;
        const dept = this.state.department;
        dept.forEach((box) => (box.isChecked = false));
        dept.find((box) => box.name === name).isChecked = true;
        let filteredTicket = tickets.filter(
            (ticket) => ticket.department === name
        );

        this.setState((prev) => ({
            ...prev,
            department: dept,
            filteredTicket,
            showPendings: true,
            search: "",
            hasSearched: false,
        }));
    };

    viewTicket = (itemData) => {
        this.setState(
            {
                itemData,
            },
            () => {
                this.setState({
                    showTicketForm: true,
                    showPendings: false,
                    hasSearched: false,
                    showTickets: false,
                });
            }
        );
    };

    updateSearch(event) {
        let tempSearchTickets = [];

        if (event.target.value !== "") {
            this.setState({
                hasSearched: true,
                showTicketForm: false,
            });
        } else {
            this.setState({
                hasSearched: false,
                showPendings: true,
            });
        }

        this.setState(
            {
                search: event.target.value.substr(0, 20),
            },
            () => {
                if (this.state.filterBy === "") {
                    tempSearchTickets = filterAll(
                        this.state.filteredTicket,
                        this.state.search
                    );
                } else {
                    let tempFilterBy = this.state.filterBy;

                    tempSearchTickets = filterBy(
                        this.state.filteredTicket,
                        this.state.search,
                        tempFilterBy
                    );
                }
                this.setState({
                    showPendings: true,
                    groupByTicket: tempSearchTickets,
                });
            }
        );
    }

    showPendingTickets = (ticketsToMap) =>
        ticketsToMap
            .map((item) => (
                <GenerateTicketTable
                    key={item.id}
                    item={item}
                    viewTicket={this.viewTicket}
                />
            ))
            .sort((a, b) => a.key - b.key);

    resetshowTicketForm = () => {
        this.setState({
            showTicketForm: false,
            showPendings: true,
        });
    };
    async getData() {
        if (this.props.isLoggedIn) {
            const res = await fetchPendingTickets("completed");
            this.setState((prev) => ({ ...prev, ...res }));
        }
    }
    componentDidMount() {
        this.getData();
    }
    render() {
        return !this.props.isLoggedIn ? (
            <></>
        ) : (
            <>
                {this.state.showPendings && (
                    <SearchBar
                        searchInput={this.state.search}
                        updateSearch={(e) => this.updateSearch(e)}
                    />
                )}
                {this.state.showPendings && (
                    <>
                        <div className='filterSearchContainer'>
                            <div className='filterCheckBoxes'>
                                <h2>Department</h2>
                                <div id='department'>
                                    {renderCheckBoxes(
                                        this.state.department,
                                        this.handleDepartmentType.bind(this)
                                    )}
                                </div>
                                {/* <h2>Group By</h2>
                <div id="groupBy">
                  {renderCheckBoxes(
                    this.state.checkboxes,
                    this.handleCheckboxFilter.bind(this)
                  )}
                </div> */}
                            </div>

                            <table>
                                <thead>
                                    <tr>
                                        <th> Id </th>
                                        <th> Store </th>
                                        <th> Country </th>
                                        <th> Room </th>
                                        <th> Completed </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.hasSearched &&
                                    this.state.groupByTicket.length >= 1 ? (
                                        this.showPendingTickets(
                                            this.state.groupByTicket
                                        )
                                    ) : this.state.hasSearched &&
                                      this.state.groupByTicket.length < 1 ? (
                                        <tr>
                                            <td></td>
                                            <td className='resultP'>
                                                No results found!
                                            </td>
                                        </tr>
                                    ) : (
                                        this.showPendingTickets(
                                            this.state.filteredTicket
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                )}
                {this.state.showTicketForm && (
                    <TicketTemplate
                        data={this.state.itemData}
                        calledFromCompleted={true}
                        resetshowTicketForm={this.resetshowTicketForm}
                    />
                )}
            </>
        );
    }
}

export default Completed;
