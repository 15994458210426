import { store } from "./roomsPerStore";

const GenerateRoomList = (selectedStore) => {
    let storeData = store.filter((item) => item.name === selectedStore);

    const storeRooms = storeData[0].rooms.sort();

    return storeRooms.map((item, index) => (
        <option
            key={index}
            value={item}>
            {item}
        </option>
    ));
};

export default GenerateRoomList;
