import { filterBy } from "../Components/utils/filterBy";
export const PROD_SERVER_URL = "https://support.cubechallenges.com/api";
export const PREV_SERVER_URL = "https://preview-support.cubechallenges.com/api";
export const DEV_SERVER_URL = "http://support.cubechallenges.com.test:8080/api";
export const SERVER_URL = PROD_SERVER_URL;

/**
 * @name login
 */
export const login = async (data) => {
    const { username, password } = data;
    const LOGIN_ENDPOINT = SERVER_URL + "/users/login.php";
    try {
        const res = await fetch(LOGIN_ENDPOINT, {
            method: "POST",
            body: JSON.stringify({
                clientId: username,
                pass: password,
            }),
        });
        if (res.status === 200) {
            return await res.json();
        } else {
            return res.json();
        }
    } catch (e) {
        console.error("[Login]: Failed to Post Username or Password" + e);
        return false;
    }
};

/**
 * @name fetchPendingTickets
 */
export const fetchPendingTickets = async (status) => {
    return await fetch(SERVER_URL + "/ticket/fetchPendingTickets.php", {
        method: "POST",
        body: JSON.stringify({
            status,
        }),
    })
        .then((res) => res.json())
        .then((response) => {
            if (response.message === "No Pending Results!") {
                return {
                    showTickets: false,
                    ticketCounter: 0,
                };
            } else {
                let filteredTicket = filterBy(
                    response.ticket,
                    "electronic",
                    "department"
                );
                return {
                    showTickets: true,
                    tickets: response.ticket,
                    filteredTicket,
                    ticketCounter: response.ticket.length,
                };
            }
        })
        .catch((err) => console.log(err));
};

/**
 * @name deleteTicket
 */
export const deleteTicket = async (itemData) => {
    return await fetch(`${SERVER_URL}/ticket/deleteTicket.php`, {
        method: "POST",
        body: JSON.stringify({
            id: parseInt(itemData.id),
        }),
    })
        .then((res) => res.json())
        .then((response) => {
            if (response.message === "success") {
                return "success";
            }
            return "failed";
        })
        .catch((err) => console.log(err));
};

/**
 * @name updateTicket
 */
export const updateTicket = async (data, callBack) => {
    return await fetch(`${SERVER_URL}/ticket/updateTicket.php`, {
        method: "POST",
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .then((response) => {
            if (response.message === "success") {
                if (data.solution !== "") {
                    callBack("solution");
                } else {
                    callBack("navigate");
                }
            } else {
                callBack("failed");
            }
        })
        .catch((err) => console.log(err));
};

/**
 * @name insertTicket
 */
export const insertTicket = async (state, handleSuccess, handleError) => {
    return await fetch(`${SERVER_URL}/ticket/insertTicket.php`, {
        method: "POST",
        body: JSON.stringify(state),
    })
        .then((res) => res.json())
        .then((response) => {
            if (response.message === "success") {
                handleSuccess(state.solution);
            } else {
                handleError(state.solution);
            }
        })
        .catch((err) => {
            handleError(err.message);
            console.log(err);
        });
};

export const logout = () => {
    console.log("logout");
    localStorage.removeItem("access_token");
    localStorage.removeItem("expire_at");
};
