import { store } from "./roomsPerStore";

const GenerateStoreList = ({ selectedCountry }) =>
    store
        .filter((item) => item.country === selectedCountry)
        .sort()
        .map((item, index) => {
            return (
                <option
                    key={index}
                    value={item.value}>
                    {item.name}
                </option>
            );
        });

export default GenerateStoreList;
