import { useState, useEffect } from "react";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import NavBar from "./Components/NavBar";
import Home from "./Components/Home/index";
import Completed from "./Components/Completed/index";
import TicketForm from "./Components/TicketForm";
import { logout, login } from "./services/api.js";
import { fetchPendingTickets } from "./services/api";

const App = () => {
    const [state, setState] = useState({
        ticketCounter: 0,
        pendingCounter: 0,
        completedCounter: 0,
        loginProfile: null,
        isLoggedIn: false,
        errMsg: "",
        pending: [],
        completed: [],
    });
    const handleLogin = async (data) => {
        try {
            const response = await login(data);
            const resState = await fetchPendingTickets("completed");
            const resPending = await fetchPendingTickets("pending");
            if (response == 1) {
                setState((prev) => ({
                    ...prev,
                    isLoggedIn: true,
                    loginProfile: data.username,
                    ...resState,
                    completedCounter: resState.ticketCounter,
                    completed: resState.tickets,
                    pending: resPending.tickets,
                }));
            } else {
                setState((prev) => ({
                    ...prev,
                    isLoggedIn: false,
                }));
            }
        } catch (error) {
            console.log(error);
        }
    };
    const getData = async () => {
        const resState = await fetchPendingTickets("completed");
        const resPending = await fetchPendingTickets("pending");
        setState((prev) => ({
            ...prev,
            completed: resState.tickets,
            pending: resPending.tickets,
        }));
    };
    const handleLogout = () => {
        logout();

        setState({
            loginProfile: null,
        });
    };

    const handleTicketCounter = (ticketCounter) => {
        setState((prev) => ({
            ...prev,
            ticketCounter,
        }));
    };
    useEffect(() => {
        if (state.isLoggedIn) {
            getData();
        }
    }, []);

    return (
        <Router>
            <NavBar
                pendingsStatus={state.ticketCounter}
                counter={{
                    pending: state.pending,
                    completed: state.completed,
                }}
                handleLogin={handleLogin}
                loginProfile={state.loginProfile}
                handleLogout={handleLogout}
            />
            <Routes>
                <Route
                    Login={state.loginProfile}
                    exact
                    path='/'
                    replace
                    Component={() => (
                        <Home
                            handleTicketCounter={handleTicketCounter}
                            pendingsStatus={state.ticketCounter}
                            profile={state.loginProfile}
                            isLoggedIn={state.isLoggedIn}
                        />
                    )}
                />
                <Route
                    path='/form'
                    replace
                    Component={() => (
                        <TicketForm
                            isLoggedIn={state.isLoggedIn}
                            handleTicketCounter={handleTicketCounter}
                            pendingsStatus={state.ticketCounter}
                        />
                    )}
                />
                <Route
                    path='/completed'
                    replace
                    Component={() => (
                        <Completed
                            Login={state.loginProfile}
                            isLoggedIn={state.isLoggedIn}
                            handleTicketCounter={handleTicketCounter}
                            pendingsStatus={state.ticketCounter}
                        />
                    )}
                />
            </Routes>
        </Router>
    );
};

export default App;
