export const allChallenges = {
    LAVALAND: "LAVALAND",
    FLOOR_IS_LAVA: "FLOOR IS LAVA",
    RED_ROPES_AREA: "RED ROPES AREA",
    RESTRICTED_AREA: "RESTRICTED AREA",
    LOST_CITY: "LOST CITY",
    KNOCK_OUT: "KNOCK OUT",
    GOLDEN_GATE_BRIDGE: "GOLDEN GATE BRIDGE",
    CARDIO: "CARDIO",
    CARDIO_SPECIAL: "CARDIO (SPECIAL)",
    SPEED_BARRELS: "SPEED BARRELS",
    GYM_TONIC: "GYM TONIC",
    ROAD_TO_OLYMPUS: "ROAD TO OLYMPUS",
    DANGER_MINEFIELD: "DANGER MINEFIELD",
    LASER_MAZE: "LASER MAZE",
    BUZZER_BEATER: "BUZZER BEATER",
    SUBBUTEO: "SUBBUTEO",
    HIT_BALL: "HIT BALL (AKA SKEEBALL)",
    SEVEN_BLOCKS: "7BLOCKS",
    COLOR_TWIST: "COLOR TWIST",
    SLINGSHOT_MADNESS: "SLINGSHOT MADNESS",
    BUBBLE_TROUBLE: "BUBBLE TROUBLE",
    CRYSTAL_MAZE: "CRYSTAL MAZE",
    CARGO: "CARGO",
    TARGET_EIGHT: "TARGET 8",
    FILL_IT: "FILL IT",
    LIGHTS_ON: "LIGHTS ON",
    CRAZY_CIRCUIT: "CRAZY CIRCUIT",
    CRAZY_CIRCUIT_SPECIAL: "CRAZY CIRCUIT (SPECIAL)",
    SHOOTING_FARM: "SHOOTING FARM",
    COMET_FORCES: "COMET FORCES",
    GOLF_IT: "GOLF IT",
    HANGRY_MONKEY: "HANGRY MONKEY",
    GENIUS: "GENIUS",
    BOMB_SQUAD: "BOMB SQUAD (AKA BEAT THE BOMB)",
    BOMB_SQUAD_SPECIAL: "BOMB SQUAD (SPECIAL)",
    RAINBOWMIND: "RAINBOWMIND",
    RAINBOWMIND_SPECIAL: "RAINBOWMIND (SPECIAL)",
    WORLDERLAND: "WORLDERLAND",
    ALL_IN: "ALL IN",
    CANDIT: "CANDIT",
    PYRAMID_OF_GIZA: "PYRAMID OF GIZA",
    SPOT_THE_DIFFERENCES: "SPOT THE DIFFERENCES",
    SPOT_THE_DIFFERENCES_SPECIAL: "SPOT THE DIFFERENCES (SPECIAL)",
    CLEF_DE_SOL: "CLEF DE SOL",
    WHOS_THERE: "WHO'S THERE?",
    GHOST_HUNTERS: "GHOST HUNTERS",
    HORROR_ROUTE: "HORROR ROUTE",
    MAGIC_POTION: "MAGIC POTION",
    LAST_SENSE: "LAST SENSE",
    MAGIC_SENSE: "MAGIC SENSE",
    LEADERBOARD: "LEADERBOARD",
    REGISTRATION_1: "REGISTRATION 1",
    REGISTRATION_2: "REGISTRATION 2",
    REGISTRATION_3: "REGISTRATION 3",
    REGISTRATION_4: "REGISTRATION 4",

    CUBE_ADMINISTRATOR: "CUBE ADMINISTRATOR",
    PROGRESS_APP_1: "PROGRESS APP 1",
    PROGRESS_APP_2: "PROGRESS APP 2",
    PROGRESS_APP_3: "PROGRESS APP 3",
};

export const countries = {
    GERMANY: "GERMANY",
    DENMARK: "DENMARK",
    FRANCE: "FRANCE",
    BELGIUM: "BELGIUM",
    ITALY: "ITALY",
    SWITZERLAND: "SWITZERLAND",
    SLOVENIA: "SLOVENIA",
    ROMANIA: "ROMANIA",
    UAE: "UNITED ARAB EMIRATES",
    SAUDI_ARABIA: "SAUDI ARABIA",
    CANADA: "CANADA",
    IRELAND: "IRELAND",
};

export const store = [
    //karlsruhe
    {
        name: "Karlsruhe",
        value: "Karlsruhe",
        country: countries.GERMANY,
        rooms: [
            allChallenges.RESTRICTED_AREA,
            allChallenges.SPEED_BARRELS,
            allChallenges.ROAD_TO_OLYMPUS,
            allChallenges.GYM_TONIC,
            allChallenges.RED_ROPES_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.CARDIO,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.CLEF_DE_SOL,
            allChallenges.BOMB_SQUAD,
            allChallenges.ALL_IN,
            allChallenges.SUBBUTEO,
            allChallenges.BUZZER_BEATER,
            allChallenges.TARGET_EIGHT,
            allChallenges.FILL_IT,
            allChallenges.LIGHTS_ON,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.CARGO,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
        ],
    },
    //kassel
    {
        name: "Kassel",
        value: "Kassel",
        country: countries.GERMANY,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RESTRICTED_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.GYM_TONIC,
            allChallenges.LASER_MAZE,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.FILL_IT,
            allChallenges.LIGHTS_ON,
            allChallenges.GENIUS,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.CLEF_DE_SOL,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
        ],
    },
    // Koln
    {
        name: "Koln",
        value: "Koln",
        country: countries.GERMANY,
        rooms: [
            allChallenges.DANGER_MINEFIELD,
            allChallenges.RESTRICTED_AREA,
            allChallenges.SPEED_BARRELS,
            allChallenges.ROAD_TO_OLYMPUS,
            allChallenges.GYM_TONIC,
            allChallenges.RED_ROPES_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.LASER_MAZE,
            allChallenges.RAINBOWMIND,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.CLEF_DE_SOL,
            allChallenges.BOMB_SQUAD,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.SUBBUTEO,
            allChallenges.BUZZER_BEATER,
            allChallenges.TARGET_EIGHT,
            allChallenges.FILL_IT,
            allChallenges.GOLF_IT,
            allChallenges.LIGHTS_ON,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.CARGO,
            allChallenges.COMET_FORCES,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.LEADERBOARD,
            allChallenges.PROGRESS_APP_1,
            allChallenges.PROGRESS_APP_2,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.CUBE_ADMINISTRATOR,
        ],
    },
    // Aalborg
    {
        name: "Aalborg",
        value: "Aalborg",
        country: countries.DENMARK,
        rooms: [
            allChallenges.DANGER_MINEFIELD,
            allChallenges.RESTRICTED_AREA,
            allChallenges.SPEED_BARRELS,
            allChallenges.ROAD_TO_OLYMPUS,
            allChallenges.GYM_TONIC,
            allChallenges.RED_ROPES_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.CARDIO,
            allChallenges.LASER_MAZE,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.CLEF_DE_SOL,
            allChallenges.BOMB_SQUAD,
            allChallenges.ALL_IN,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.SUBBUTEO,
            allChallenges.BUZZER_BEATER,
            allChallenges.TARGET_EIGHT,
            allChallenges.FILL_IT,
            allChallenges.GOLF_IT,
            allChallenges.LIGHTS_ON,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.CARGO,
            allChallenges.COMET_FORCES,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.LEADERBOARD,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
            allChallenges.PROGRESS_APP_2,
        ],
    },
    // Lyon
    {
        name: "Lyon",
        value: "Lyon",
        country: countries.FRANCE,
        rooms: [
            allChallenges.DANGER_MINEFIELD,
            allChallenges.RESTRICTED_AREA,
            allChallenges.SPEED_BARRELS,
            allChallenges.FLOOR_IS_LAVA,
            allChallenges.RED_ROPES_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.LASER_MAZE,
            allChallenges.RAINBOWMIND,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.CLEF_DE_SOL,
            allChallenges.BOMB_SQUAD,
            allChallenges.ALL_IN,
            allChallenges.GENIUS,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.SUBBUTEO,
            allChallenges.BUZZER_BEATER,
            allChallenges.TARGET_EIGHT,
            allChallenges.FILL_IT,
            allChallenges.LIGHTS_ON,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.CARGO,
            allChallenges.HIT_BALL,
            allChallenges.COMET_FORCES,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.LEADERBOARD,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
            allChallenges.PROGRESS_APP_2,
        ],
    },
    // Genk
    {
        name: "Genk",
        value: "Genk",
        country: countries.BELGIUM,
        rooms: [
            allChallenges.RED_ROPES_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.RESTRICTED_AREA,
            allChallenges.GYM_TONIC,
            allChallenges.LAVALAND,
            allChallenges.ROAD_TO_OLYMPUS,
            allChallenges.LOST_CITY,
            allChallenges.TARGET_EIGHT,
            allChallenges.SUBBUTEO,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.CARGO,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.HIT_BALL,
            allChallenges.COMET_FORCES,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.ALL_IN,
            allChallenges.GENIUS,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.RAINBOWMIND,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.LEADERBOARD,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
            allChallenges.PROGRESS_APP_2,
        ],
    },
    //Rome
    {
        name: "Rome",
        value: "Rome",
        country: countries.ITALY,
        rooms: [
            allChallenges.RED_ROPES_AREA,
            allChallenges.SPEED_BARRELS,
            allChallenges.KNOCK_OUT,
            allChallenges.RESTRICTED_AREA,
            allChallenges.CARDIO,
            allChallenges.GYM_TONIC,
            allChallenges.LAVALAND,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.LOST_CITY,
            allChallenges.TARGET_EIGHT,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.LIGHTS_ON,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.HIT_BALL,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.GENIUS,
            allChallenges.CLEF_DE_SOL,
            allChallenges.BOMB_SQUAD,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.RAINBOWMIND,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.WORLDERLAND,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.LEADERBOARD,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.REGISTRATION_4,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
            allChallenges.PROGRESS_APP_2,
            allChallenges.PROGRESS_APP_3,
        ],
    },
    {
        name: "Geneva",
        value: "Geneva",
        country: countries.SWITZERLAND,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.SPEED_BARRELS,
            allChallenges.LASER_MAZE,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.LIGHTS_ON,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.ALL_IN,
            allChallenges.CLEF_DE_SOL,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.LEADERBOARD,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
            allChallenges.PROGRESS_APP_2,
        ],
    },
    {
        name: "Murska Sobota",
        value: "Murska Sobota",
        country: countries.SLOVENIA,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.LOST_CITY,
            allChallenges.KNOCK_OUT,
            allChallenges.SPEED_BARRELS,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.CARGO,
            allChallenges.LIGHTS_ON,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.ALL_IN,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.CLEF_DE_SOL,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.REGISTRATION_4,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
        ],
    },
    {
        name: "Rudnik",
        value: "Rudnik",
        country: countries.SLOVENIA,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.RESTRICTED_AREA,
            allChallenges.LOST_CITY,
            allChallenges.KNOCK_OUT,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.TARGET_EIGHT,
            allChallenges.FILL_IT,
            allChallenges.GENIUS,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.ALL_IN,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.CLEF_DE_SOL,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.REGISTRATION_4,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
        ],
    },
    {
        name: "Bucuresti",
        value: "Bucuresti",
        country: countries.ROMANIA,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.RESTRICTED_AREA,
            allChallenges.LOST_CITY,
            allChallenges.KNOCK_OUT,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.SPEED_BARRELS,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CARGO,
            allChallenges.TARGET_EIGHT,
            allChallenges.CRAZY_CIRCUIT,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.ALL_IN,
            allChallenges.CANDIT,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.CLEF_DE_SOL,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
        ],
    },
    {
        name: "Poitiers",
        value: "Poitiers",
        country: countries.FRANCE,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.RESTRICTED_AREA,
            allChallenges.LOST_CITY,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.CARDIO,
            allChallenges.GYM_TONIC,
            allChallenges.ROAD_TO_OLYMPUS,
            allChallenges.DANGER_MINEFIELD,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.CARGO,
            allChallenges.TARGET_EIGHT,
            allChallenges.CRAZY_CIRCUIT,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.CANDIT,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.CLEF_DE_SOL,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
        ],
    },
    {
        name: "Abu Dhabi",
        value: "Abu Dhabi",
        country: countries.UAE,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.RESTRICTED_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.SPEED_BARRELS,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.CARGO,
            allChallenges.TARGET_EIGHT,
            allChallenges.FILL_IT,
            allChallenges.LIGHTS_ON,
            allChallenges.CRAZY_CIRCUIT,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.ALL_IN,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.CLEF_DE_SOL,
            allChallenges.WHOS_THERE,
            allChallenges.GHOST_HUNTERS,
            allChallenges.HORROR_ROUTE,
            allChallenges.MAGIC_POTION,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.REGISTRATION_4,

            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
            allChallenges.PROGRESS_APP_2,
        ],
    },
    {
        name: "Lisburn",
        value: "Lisburn",
        country: countries.IRELAND,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.RESTRICTED_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.CARDIO,
            allChallenges.SPEED_BARRELS,
            allChallenges.GYM_TONIC,
            allChallenges.LASER_MAZE,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.TARGET_EIGHT,
            allChallenges.FILL_IT,
            allChallenges.LIGHTS_ON,
            allChallenges.CRAZY_CIRCUIT,
            allChallenges.SHOOTING_FARM,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.ALL_IN,
            allChallenges.CANDIT,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.CLEF_DE_SOL,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
        ],
    },
    {
        name: "Lille",
        value: "Lille",
        country: countries.FRANCE,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RESTRICTED_AREA,
            allChallenges.LOST_CITY,
            allChallenges.KNOCK_OUT,
            allChallenges.GYM_TONIC,
            allChallenges.ROAD_TO_OLYMPUS,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.CARGO,
            allChallenges.LIGHTS_ON,
            allChallenges.CRAZY_CIRCUIT,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.WORLDERLAND,
            allChallenges.CANDIT,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
        ],
    },
    {
        name: "Jeddah",
        value: "Jeddah",
        country: countries.SAUDI_ARABIA,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.RESTRICTED_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.CARDIO,
            allChallenges.SPEED_BARRELS,
            allChallenges.LASER_MAZE,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.TARGET_EIGHT,
            allChallenges.FILL_IT,
            allChallenges.LIGHTS_ON,
            allChallenges.CRAZY_CIRCUIT_SPECIAL,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.ALL_IN,
            allChallenges.CANDIT,
            allChallenges.CLEF_DE_SOL,
            allChallenges.WHOS_THERE,
            allChallenges.GHOST_HUNTERS,
            allChallenges.HORROR_ROUTE,
            allChallenges.MAGIC_POTION,
            allChallenges.LAST_SENSE,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.REGISTRATION_4,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
            allChallenges.PROGRESS_APP_2,
        ],
    },
    {
        name: "Bornem",
        value: "Bornem",
        country: countries.BELGIUM,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RESTRICTED_AREA,
            allChallenges.LOST_CITY,
            allChallenges.KNOCK_OUT,
            allChallenges.ROAD_TO_OLYMPUS,
            allChallenges.DANGER_MINEFIELD,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.TARGET_EIGHT,
            allChallenges.CRAZY_CIRCUIT,
            allChallenges.SHOOTING_FARM,
            allChallenges.BOMB_SQUAD,
            allChallenges.WORLDERLAND,
            allChallenges.CANDIT,
            allChallenges.CLEF_DE_SOL,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
        ],
    },
    {
        name: "Arbon",
        value: "Arbon",
        country: countries.SWITZERLAND,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.SUBBUTEO,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CARGO,
            allChallenges.TARGET_EIGHT,
            allChallenges.LIGHTS_ON,
            allChallenges.SHOOTING_FARM,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.ALL_IN,
            allChallenges.CANDIT,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.CLEF_DE_SOL,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
        ],
    },
    {
        name: "Orleans",
        value: "Orleans",
        country: countries.FRANCE,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.RESTRICTED_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.SPEED_BARRELS,
            allChallenges.GYM_TONIC,
            allChallenges.ROAD_TO_OLYMPUS,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.CARGO,
            allChallenges.TARGET_EIGHT,
            allChallenges.FILL_IT,
            allChallenges.CRAZY_CIRCUIT,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.ALL_IN,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.CLEF_DE_SOL,
            allChallenges.WHOS_THERE,
            allChallenges.GHOST_HUNTERS,
            allChallenges.HORROR_ROUTE,
            allChallenges.MAGIC_POTION,
            allChallenges.LAST_SENSE,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.REGISTRATION_4,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
            allChallenges.PROGRESS_APP_2,
        ],
    },
    {
        name: "Plan de Campagne",
        value: "Plan de Campagne",
        country: countries.FRANCE,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.RESTRICTED_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.CARDIO_SPECIAL,
            allChallenges.SPEED_BARRELS,
            allChallenges.GYM_TONIC,
            allChallenges.LASER_MAZE,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.TARGET_EIGHT,
            allChallenges.CRAZY_CIRCUIT,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.CANDIT,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.WHOS_THERE,
            allChallenges.GHOST_HUNTERS,
            allChallenges.HORROR_ROUTE,
            allChallenges.MAGIC_POTION,
            allChallenges.LAST_SENSE,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.REGISTRATION_4,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
            allChallenges.PROGRESS_APP_2,
        ],
    },
    {
        name: "Pontault Combault",
        value: "Pontault Combault",
        country: countries.FRANCE,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.RESTRICTED_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.SPEED_BARRELS,
            allChallenges.GYM_TONIC,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.TARGET_EIGHT,
            allChallenges.FILL_IT,
            allChallenges.CRAZY_CIRCUIT,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.ALL_IN,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.CLEF_DE_SOL,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
        ],
    },
    {
        name: "Selestat",
        value: "Selestat",
        country: countries.FRANCE,
        rooms: [
            allChallenges.LAVALAND,
            allChallenges.RED_ROPES_AREA,
            allChallenges.RESTRICTED_AREA,
            allChallenges.KNOCK_OUT,
            allChallenges.GOLDEN_GATE_BRIDGE,
            allChallenges.CARDIO,
            allChallenges.GYM_TONIC,
            allChallenges.LASER_MAZE,
            allChallenges.BUZZER_BEATER,
            allChallenges.SUBBUTEO,
            allChallenges.HIT_BALL,
            allChallenges.SEVEN_BLOCKS,
            allChallenges.COLOR_TWIST,
            allChallenges.SLINGSHOT_MADNESS,
            allChallenges.BUBBLE_TROUBLE,
            allChallenges.CRYSTAL_MAZE,
            allChallenges.CARGO,
            allChallenges.TARGET_EIGHT,
            allChallenges.FILL_IT,
            allChallenges.LIGHTS_ON,
            allChallenges.CRAZY_CIRCUIT,
            allChallenges.GENIUS,
            allChallenges.BOMB_SQUAD,
            allChallenges.RAINBOWMIND,
            allChallenges.WORLDERLAND,
            allChallenges.ALL_IN,
            allChallenges.CANDIT,
            allChallenges.PYRAMID_OF_GIZA,
            allChallenges.SPOT_THE_DIFFERENCES,
            allChallenges.CLEF_DE_SOL,
            allChallenges.WHOS_THERE,
            allChallenges.GHOST_HUNTERS,
            allChallenges.HORROR_ROUTE,
            allChallenges.MAGIC_POTION,
            allChallenges.LAST_SENSE,
            allChallenges.LEADERBOARD,
            allChallenges.REGISTRATION_1,
            allChallenges.REGISTRATION_2,
            allChallenges.REGISTRATION_3,
            allChallenges.REGISTRATION_4,
            allChallenges.CUBE_ADMINISTRATOR,
            allChallenges.PROGRESS_APP_1,
            allChallenges.PROGRESS_APP_2,
        ],
    },
];
