import React from "react";
import "./style.css";
import DropDown from "./Dropdown";
import TextArea from "./Textarea";
import GenerateCountryList from "../utils/generateCountryList";
import GenerateStoreList from "../utils/generateStoreList";
import GenerateRoomList from "../utils/generateRoomList";
import timeHelper from "../utils/timeHelper";
import renderCheckBoxes from "../Helpers/renderCheckBoxes";
import { insertTicket } from "../../services/api";
import { DEPARTMENT_CHECKBOXES } from "../../constants";

class TicketForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            country: "GERMANY",
            store: "Karlsruhe",
            room: "RESTRICTED AREA",
            report: "",
            cause: "",
            assistant: "",
            client: "",
            email: "",
            isSolved: false,
            solution: "",
            createdDate: "",
            completedDate: "1970-01-01",
            status: "pending",
            department: DEPARTMENT_CHECKBOXES,
            checkboxes: DEPARTMENT_CHECKBOXES,
            errorMessage: "",
            errorEmail: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckboxFilter = this.handleCheckboxFilter.bind(this);
    }

    handleCheckboxFilter(event) {
        const { name } = event.target;
        const boxes = this.state.checkboxes;
        boxes.forEach((box) => (box.isChecked = false));
        boxes.find((box) => box.name === name).isChecked = true;

        this.setState((prev) => ({
            ...prev,
            department: name,
        }));
    }

    handleChange(event) {
        const { name, value } = event.target;

        if (name === "country") {
            this.setState(
                {
                    [name]: value,
                },
                () => {
                    let store = document.getElementsByName("store")[0].value;
                    this.setState(
                        {
                            store,
                        },
                        () => {
                            let room =
                                document.getElementsByName("room")[0].value;
                            this.setState({
                                room,
                            });
                        }
                    );
                }
            );
        } else if (name === "store") {
            this.setState(
                {
                    [name]: value,
                },
                () => {
                    let room = document.getElementsByName("room")[0].value;
                    this.setState({
                        room,
                    });
                }
            );
        }

        this.setState({
            [name]: value,
        });
    }

    handleCheckbox = (event) => {
        const { name, value } = event.target;

        let completedDate = timeHelper();

        if (name === "solution" && value.length > 0) {
            this.setState({
                isSolved: true,
                [name]: value,
                status: "completed",
                completedDate: completedDate,
            });
        } else {
            this.setState({
                isSolved: false,
                status: "pending",
                solution: "",
            });
        }
    };

    componentDidMount() {
        document.querySelectorAll("input[type=checkbox]").forEach((item) => {
            if (item.name === "electronic") {
                item.checked = true;
            }
        });

        let currentDate = timeHelper();

        this.setState({
            createdDate: currentDate,
        });
    }

    handleTicketCounterForm = () => {
        let newTicketCounter = 0;
        let oldTicketCounter = this.props.pendingsStatus;

        if (this.state.solution === "") {
            newTicketCounter = oldTicketCounter + 1;
            this.props.handleTicketCounter(newTicketCounter);
        } else {
            this.props.handleTicketCounter(oldTicketCounter);
        }
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        const validateEmail = (email) => {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

            if (!re.test(email.toLowerCase())) {
                this.setState({
                    errorEmail: true,
                });
            } else {
                this.setState({
                    errorEmail: false,
                });
            }
            return re.test(email);
        };

        if (
            !validateEmail(this.state.email) ||
            this.state.report === "" ||
            this.state.cause === "" ||
            this.state.asssistant === "" ||
            this.state.client === ""
        ) {
            this.setState({
                errorMessage: "Please fill the blanks",
            });
        } else {
            await insertTicket(
                this.state,
                () => {
                    this.handleTicketCounterForm(this.state.solution);
                },
                (err) => {
                    this.setState({
                        errorMessage: err,
                    });
                }
            );
        }
    };

    render() {
        return (
            <div>
                <form className='formContainer'>
                    <div className='formItem'>
                        <DropDown
                            name='country'
                            handleChange={this.handleChange}>
                            <GenerateCountryList />
                        </DropDown>
                        <DropDown
                            name='store'
                            handleChange={this.handleChange}>
                            <GenerateStoreList
                                selectedCountry={this.state.country}
                            />
                        </DropDown>
                        <DropDown
                            name='room'
                            handleChange={this.handleChange}>
                            {GenerateRoomList(this.state.store)}
                        </DropDown>
                        <p>
                            DATE:
                            <span> {this.state.createdDate} </span>
                        </p>
                        <div id='ticketForm__checkbox'>
                            {renderCheckBoxes(
                                this.state.checkboxes,
                                this.handleCheckboxFilter.bind(this)
                            )}
                        </div>
                    </div>
                    <div className='formItem-2'>
                        <div className='row'>
                            <TextArea
                                name='report'
                                handleChange={this.handleChange}
                            />
                            <TextArea
                                name='cause'
                                handleChange={this.handleChange}
                            />
                        </div>
                        <div className='row'>
                            <TextArea
                                name='assistant'
                                handleChange={this.handleChange}
                            />
                            <TextArea
                                name='client'
                                handleChange={this.handleChange}
                            />
                        </div>
                        <div>
                            <TextArea
                                name='email'
                                handleChange={this.handleChange}
                            />
                            {this.state.errorEmail ? (
                                <span style={{ color: "red" }}>
                                    Email Invalid
                                </span>
                            ) : undefined}
                        </div>
                        <div className='solutionInput'>
                            <label className='label'> SOLUTION </label>
                            <textarea
                                name='solution'
                                cols='10'
                                rows='5'
                                onChange={this.handleCheckbox}
                            />
                            <p className='checkBoxStyle'>
                                <input
                                    id='test1'
                                    name='isSolved'
                                    type='checkbox'
                                    readOnly
                                    checked={this.state.isSolved}
                                    onChange={this.handleCheckbox}
                                />
                                <label htmlFor='test1'>Resolved</label>
                                <span className='resolved'>Resolved</span>
                            </p>
                        </div>
                    </div>
                </form>
                <p
                    className='submitButton'
                    onClick={this.handleSubmit}>
                    SUBMIT
                </p>
                <p className='errorMessage'> {this.state.errorMessage} </p>
            </div>
        );
    }
}

export default TicketForm;
