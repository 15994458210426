import { store } from "./roomsPerStore";

let countries = store.map((location) => {
    return location.country;
});

countries = [...new Set(countries)].sort();

const GenerateCountryList = () =>
    countries.map((item, index) => (
        <option
            key={index}
            value={item}>
            {item.toUpperCase()}
        </option>
    ));

export default GenerateCountryList;
