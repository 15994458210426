const timeHelper = () => {
    let tempDate = new Date();

    return (
        tempDate.getFullYear() +
        "-" +
        (tempDate.getMonth() + 1) +
        "-" +
        tempDate.getDate()
    );
};

export default timeHelper;
