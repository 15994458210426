const filterAll = (ticket, search) =>
    ticket.reduce((acc, item) => {
        if (
            //GET VALUES TO ARRAY CONVERT TO LOWERCASE AND SEE IF MATCHES WITH SEARCH VALUE
            Object.values(item)
                .map((items) =>
                    items.toLowerCase().includes(search.toLowerCase())
                )
                .indexOf(true) !== -1
        ) {
            return [...acc, item];
        }
        return acc;
    }, []);
export default filterAll;
